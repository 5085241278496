// Sitewide Images
import logo from "./img/sym-logo-inverse.svg";
import footerLogo from './img/sym-logo.svg';

// Home Page Images
import heroImg1 from "./img/header/hero-image-1.png";
import heroImg2 from "./img/header/hero-image-2.png";
import heroImg3 from "./img/header/hero-image-3.png";
import client01 from "./img/clients/client-1.png";
import client02 from "./img/clients/client-2.png";
import client03 from "./img/clients/client-3.png";
import client04 from "./img/clients/client-4.png";
import client05 from "./img/clients/client-5.png";
import client06 from "./img/clients/client-6.png";
import client07 from "./img/clients/client-7.png";
import client08 from "./img/clients/client-8.png";
import client09 from "./img/clients/client-9.png";
import homeRevenueDashboard from './img/revenue-dashboard.png'

// About Page Images
import aboutHeroImage from './img/about-header.jpg'
import aboutContentImage from './img/about-image.jpg'
import manag_photo1 from './img/team/manage1.png';
import manag_photo2 from './img/team/manage2.png';

// Career Page Images
import careerHeader from "./img/blog-header.jpg";
import careerContentImage from "./img/careers-img.jpg";

// Contact Page Images
import contactHeader from "./img/contact-header.jpg";

// Services Page Images
import servicesHeader from './img/services-header.jpg';
import service1 from "./img/service1-bg.png";
import service2 from "./img/service2-bg.png";
import service3 from "./img/service3-bg.png";
import service4 from "./img/service4-bg.png";

// Consortium Page Images
import consortiumC from './img/consortium.png';
import lexMedia from './img/consortium/lex-media.png';
import aCompany from './img/consortium/a-company.png';
import arGold from './img/ar-gold.png';
import lexSquare from './img/consortium/lex-square.png';

export const enterprise = {
  name: "A Company",
  name_legal: "Purpose First Foundation",
  address_gps: `
2000 Colorado Blvd.
Tower One STE 200
Denver, CO 80222
  `,
  address_mailing: `P.O. Box 14101, Austin, TX 78756`,
  email: 'contact@aurarians.com',
  phone: '970-930-1337 (message)',
  what_do: 'A Company is the Premier Provider of Purpose-First Education, Entertainment, and VAS Technology.',
  site_address: 'https://aurarians.com'
};

export const consortium = [
  {
    identity: 'lex-media',
    entityType: 'company',
    squareImage: lexSquare,
    name: 'LEX Media',
    image: lexMedia,
    category: 'Media Entertainment',
    information: `Lex Media entertainment company offers bread for wise people in the forms of humor, information, and wealth. Lex Makes Happy. This Is Lex.`,
    director: 'Director: Aleksei D.',
    purposeFirstPage: 'https://pf.lexmediaa.com',
    cta: {
      label: 'Get All-Access at LEX',
      href: 'https://lexmediaa.com'
    },
    iconLinkLeadIn: 'Follow LEX',
    iconLinks: [
      {
        icon: 'youtube',
        href: 'https://youtube.com/@makelex'
      },
      {
        icon: 'linkedin',
        href: 'https://linkedin.com/company/asgoodcompany'
      }
    ],
    longForm: `
Lex makes **NEW**. Lex makes **FUN**. Lex makes **GOLD**.

In a world where all media streams are the same, all presentations are the same, and all content is the same old stories told the same old ways...
Lex makes NEW, Lex makes FUN, and Lex makes GOLD!

Lex makes media for, by, and OF Original Voices. Lex is media for, by, and of voices of the population which have been historically ignored and misrepresented by modern media.
Be it birthplace demographics, lifestyle demographics, or non-ordinary presentation styles, Lex makes creative voices heard. This is one way it is new!

Aren't you tired of the same ol' Like, Comment, and Subscribe to my bust paradigm?! According to modern media, the audience is a bunch of mindless fools.
Not at Lex where the audience steps into their Creative Boots and does their part in Making Lex. There's no mindlessness at Lex, where the content ranges from
"Good for Everyone" to "Divulgence of Meta Reality." Get those thinking caps ready; This is Lex 🎓

Lex's Gold is a treasure of the people! Just for us, the media that comes from Lex rejects the constant stream of debasement found in 99.99% of standard, nongold
      modern media providings. Lex reimagines media production and delivery to produce Gold Standard quality.

LEX Media production is now seeking actors, sequencers, and video production experts for upcoming, scripted series *It's a God Thing*. Apply at <a href="https://aurarians.com/careers" target="_blank">Aurarians.com</a>.
    `,
  }, {
    identity: 'a',
    entityType: 'company',
    squareImage: aCompany,
    name: 'A Company',
    image: aCompany,
    category: 'Purpose First Enterprise',
    information: `A Company is the unrivaled steward of the Purpose First Initiative. A's services and Consortium provide opportunities for all to engage Purpose First.`,
    director: '',
    purposeFirstPage: 'https://purpose1st.org',
    cta: {
      label: 'Contact Aurarian for Membership and Services',
      href: 'https://aurarians.com/contact'
    },
    iconLinkLeadIn: false,
    iconLinks: false,
    // iconLinks: [
    //   {
    //     icon: 'youtube',
    //     href: 'https://youtube.com/@makelex'
    //   },
    //   {
    //     icon: 'linkedin',
    //     href: 'https://linkedin.com/company/asgoodcompany'
    //   }
    // ],
    longForm: `
A Company ("A" for short) is the steward of the Purpose First Initiative and the Premier Provider of Purpose-First Education, Entertainment, and VAS Technology. A's services and Consortium provide opportunities for individuals and collectives to engage Purpose First.

A's services include turnkey platforms in Education, Entertainment Distribution, and further business value-adds. A serves the purpose oriented clientbase, and provides memebership to individuals and clients through the Membership. Contact A Company today to affirm one's
commitment to Purpose First and become one of A's VIP clientele.

A's Aurarians are companies who, by personal recognition and agreement, have commited to carrying the lit torch of Purpose First. These entities, members of A's collaborative efforts, are recommended businesses and individuals, and benefit from collaborative effort.

A's Service Providings are available through A's Good Companies, Aurarians of Distiction.
    `
  }
];

// Carousel, found on home page
const carousel = [{
  heading: `
<h2>
  The Premier Provider of Purpose-First Technology.
</h2>
  `,
  information: `A Company is the Premier Provider of Purpose-First Deliveries in Education, Entertainment, and Service Provider Value Adds.`,
  buttonCTA: 'Read More', // text only
  buttonHref: '/about', // text only
  videoId: 'yqWX86uT5jM', // text only
  videoCTA: `Watch Intro`, // text only
  sliderImages: [
    {
      image: heroImg1,
      alt: 'education apps'
    },
    {
      image: heroImg2,
      alt: 'entertainment apps'
    },
    {
      image: heroImg3,
      alt: 'apps'
    }
  ]
}];

// Home Services
const homeServices = {
  homeServicesHeading: `# Value Proposition`,
  homeServicesInformation: `A Company is the Premier Provider of Purpose-First Technology, Offering Services in Education, Entertainment, and Service Provider Value Adds.`,
  homeServices: [{
    title: 'Educational Content', // text only,
    color: '#d38b0e', // text only
    icon: 'book', //text only
    information: `
      Education is the key to unlocking the golden door of
      freedom. A's Educational content is a vital service as it is an
      investment in human capital that yields tremendous benefits
      to personal development...
    `,
    cta: `Read More`,
    href: '/services' // text only
  }, {
    title: 'Entertainment Content', //text only,
    color: '#d38b0e', // text only
    icon: 'collection-play', //text only
    information: `
      A's Entertainment content can keep the consumer engaged for
      hours if that’s what one is interested in. A's Media produces an
      impressive array of content from Movies, Music, On-Demand
      Videos and more...
    `,
    cta: `Read More`,
    href: '/services' // text only
  }, {
    title: 'Products', //text only,
    color: '#d38b0e', // text only
    icon: 'controller', //text only
    information: `
      Enhance Consumer Services with A's Interactive Media.
      Bring something new to the audience you already serve customized
      with your brand and messaging...
    `,
    cta: `Read More`,
    href: '/services' // text only
  }, {
    title: 'News', //text only,
    color: '#d38b0e', // text only
    icon: 'universal-access-circle', //text only
    information: `
      A's surveys and provides real-time data and news updates aggregated
      for the mission of spreading awareness of Purpose-First efficacy. A Unique Value Add for Companies and Individuals...
    `,
    cta: `Read More`,
    href: '/services' // text only
  }]
};

// Home Clients
const homeClients = {
  homeClientListHeading: `## A's Aurarians`,
  homeClientLogos: [
    client01,
    client02,
    client03,
    client04,
    client05,
    client06,
    client07,
    client08,
    client09,
  ]
};

// Home Facts
const homeFacts = {
  homeFactsHeading: `<h2 class="text-white">A's Footprint</h2>`,
  homeFactsInformation: `Delivering Service Excellence Across Geography…`,
  homeFactsCounters: [{
    count: '17',
    heading: `Countries`,
    quality: 'International Presence'
  }, {
    count: '263',
    heading: `Members`,
    quality: 'Across the Globe'
  }, {
    count: '1.3M',
    heading: `Interactions`,
    quality: 'Per Month'
  }],
};

// Home Revenue
const homeRevenue = {
  homeRevenueHeading: `## Directed By Purpose and Improving Business`,
  homeRevenueInformation: `
A's enrichment services come with years of expertise in providing spiritual wellness and enlightenment value-adds, offering software and structured guides that cater to directed paths of inner growth. Businesses leveraging VAS services benefit from tailored solutions that enhance customer engagement, fostering long-term relationships and boosting overall business growth.

A's revelatory goals and break-through technology empower individuals and business to achieve greater clarity and balance, fostering desired transformation and improved wellness. By integrating A's innovative services, businesses unlock new revenue streams, improving customer satisfaction and driving sustained financial success.

A's committed to unveiling the surest pathway to enlightenment as one intuits it should be, delivering tailored experiences that support each unique journey. Similarly, A Company helps businesses explore fresh avenues for revenue generation, offering bespoke solutions that align with customer needs and ensure profitable outcomes.
  `,
  homeRevenueCta: 'Read More',
  homeRevenueHref: '/about',
  homeRevenueImage: homeRevenueDashboard,
  homeRevenueImageAlt: 'business dashboard'
};

// Home Page
// Consists of carousel, clients, facts, and revenue
export const homepage = {
  headTitle: enterprise.name,
	carousel,
	homeServices,
	homeClients,
	homeFacts,
	homeRevenue
};

// Navbar
export const navbar = {
  logo: logo,
  navLinks: [
    {
      text: 'Home',
      href: '/',
    },
    {
      text: 'About Us',
      href: '/about',
    },
    {
      text: 'Services',
      href: '/services',
    },
    {
      text: 'Careers',
      href: '/careers',
    },
    {
      text: 'Contact',
      href: '/contact',
  }]
}

// About Page
export const aboutpage = {
  headTitle: `${enterprise.name} | About`,
  aboutHeroHeading: `About Us`, // text only
  aboutHeroImage: aboutHeroImage,
  aboutPageHeading: `## Purpose First`,
  aboutContentImage: aboutContentImage,
  aboutInformation: `
  # About A Company

  A Company was founded in 2024 with a clear purpose: Exemplify and Provide A's Purpose First Principles.
  The company’s mission is to reveal the discoveries behind the Purpose First Initiative and invigorate thereby.
  By offering innovations founded purpose-first, A Company helps individuals and businesses focus and achieve.

  The theme of A Company is Purpose and Achievement. By Purpose, a goal is set, and at Achievement, Purpose is recognized.
  A Company provides educational programs, turnkey software, and technology solutions for Setting Purpose and Engaging Achievement.

  A Company is committed to fostering a community of Purpose-Aligned Fellowship. Through Consortium Membership, programs, and events,
  A's provides access to exclusive initiatives, accelerators, and opportunities that further the mission of individual entities along with A's Purpose First.
  `,
  aboutCards: [{
    title: 'Vision',
    information: `
  The Aurarian vision is the global recognition of the efficacy of Purpose First Initiative by the Achievement of Purpose-Aligned Goals and Directives.
  `
  }, {
    title: 'Mission',
    information: `
  To reveal the discoveries behind the Purpose First Initiative and invigorate a membership and consumer-base with the production of Purpose First technologies.
  `
  }, {
    title: "A's Values",
    information: `
  Purpose First starts with Purpose and ends in Achievement. This is achieved by Fidelity, Dedication, and Measurement.
  With these guiding values, substantially valuable results which are assured.
  `
  }],
  aboutSection2: {
    heading: '## The Company',
    information: `Leveraging their experience within the value added service industry, A's leadership team is dedicated to meet and exceed expectations with innovative and cost-effective solutions, industry know-how and unmatched services.`
  },
  // Members photos are at /src/img/manage[1,2].png
  aboutMembers: [{
    image: manag_photo1,
    name: '#### Alexa A.',
    role: 'Founder',
    information: "Alexa is a visionary with decades of experience in Software Technology and Natural Science. Alexa's discoveries are the fuel to formulaic innovation at A Company.",
    // link.site can be one of https://icons.getbootstrap.com/
    links: [{
       href: 'https://alexa-anderson.com',
       site: 'window'
    }, {
       href: 'https://linkedin.com/in/alexa-anderson',
       site: 'linkedin'
    }]
  }, {
    image: manag_photo2,
    name: '#### THE LEX',
    role: 'Founder & Directors',
    information: `
  Lex Starpower leads A's Director Union, the leadership of A's Purpose First Consortium. Each Director is a master of their industry, and formulates how their company exemplifies the standards of A's Purpose First Foundations.
    `,
    links: [{
       href: 'https://x.com/asgoodx',
       site: 'twitter'
    }, {
       href: 'https://linkedin.com/in/iamalexbrady',
       site: 'linkedin'
    }]
  }],
};

// Career Page
export const careerpage = {
  headTitle: `${enterprise.name} | Careers`,
	careerHeroHeading: 'Careers', // text only
	careerHeroImage: careerHeader,
  careerHeading: `## Fueled With PURPOSE, Do What You LOVE`,
  careerContentImage: careerContentImage,
  careerInformation: `
As A Company and Consortium continue to experience expansion, talented individuals are encouraged to apply to one of the open positions.

At A's, personnel development is taken to be one of the highest callings. So clear is our vision for the personal development for all individual representatives of A's,
there is have a dedicated agency for managing personnel and their purposeful careers.

Are you a talented individual? Are you encouraged by the Purpose-First Mission and Vision at A's? Apply by sending a resume to the email address outlined on this page.
  `,
  careerBlockquote: `Please send your resumes to [${enterprise.email}](mailto:${enterprise.email})`
};

// Contact Page
export const contactpage = {
  headTitle: `${enterprise.name} | Contact`,
  contactHeroHeading: 'Contact', // text only
  contactHeroImage: contactHeader,
  contactPageHeading: `## Reach A Representative`,
//   contactGPSAddress: `
// #### Location: 
// ##### Local:
// ${enterprise.address_gps}
// ##### Shipping:
// ${enterprise.address_mailing}
//   `,
  contactEmailAddress: `
#### Email:
[${enterprise.email}](mailto:info@${enterprise.email})
  `,
//   contactTelephoneAddress: `
// #### Phone:
// ${enterprise.phone}
//   `,
  contactFormCTA: 'Send Message',
  contactUsernameValidationError: `Name is too short (2 minimum)`,
  contactEmailValidationError: `Please enter a valid email address`,
  contactSubjectValidationError: `Subject is too short (5 minimum)`,
  contactMessageValidationError: `Message is too short (10 minimum)`,
  contactSubmissionSuccessMessage: `Successfully submitted ✓`,
  contactSubmissionErrorMessage: `Unable to contact server. Please send this message to ${enterprise.email}.`
};

// Services Page
export const servicespage = {
  headTitle: `${enterprise.name} | Services`,
  servicesHeroHeading: 'Services', // text only
  servicesHeroImage: servicesHeader,
  servicesHeading: `## A Company is the <span style="color:#d38b0e">PREMIER PROVIDER</span> of Purpose-First, Offering Services in Education, Entertainment, and Service Provider Value Adds.`,
  services: [ 
    {
      id: 'educational-content', // text only
      color: '#d38b0e', // text only
      icon: 'journals',
      heading: `#### Educational Content`,
      information: `
A's Educational Content is a vital service as it is an
investment in human capital that yields tremendous benefits with Purpose-First development.

A's Educational Content provides membership with the skills necessary to start and succeed with Purpose-First Methodology.

Educational Content is presented with A's proprietary technology and includes self-paced, guided, and live tutorial presentations.
      `,
      image: service1,
      name: 'Educational Content'
    },
    {
      id: 'entertainment-content', // text only
      color: '#d38b0e', // text only
      icon: 'collection-play',
      heading: `#### Entertainment Content`,
      information: `
A's Entertainment can keep the consumer engaged for hours if that’s what one is interested in. A's Media produces an
impressive array of content from Movies, Music, On-Demand Videos and more.

Everytime a customer needs enthralling content, A's Entertainment is the way to go.
      `,
      image: service2,
      name: 'Entertainment Content'
    },
    {
      id: 'products', // text only
      color: '#d38b0e', // text only
      icon: 'controller',
      heading: `#### Products`,
      information: `
Enhance Consumer Services with A's Interactive Media. Bring something new to the audience you already serve customized with your brand and messaging.

Whether Digital or Physical, A's Private-Label products are an obvious choice for businesses to add an entertainment element. For increased customer engagement, A's is the way to go!
      `,
      image: service3,
      name: 'Products'
    },
    {
      id: 'news', // text only
      color: '#d38b0e', // text only
      icon: 'universal-access-circle',
      heading: `#### News`,
      information: `
A's surveys and provides real-time data and news updates for the mission of spreading awareness of Purpose-First efficacy. This unique value add for Companies and Individuals serves to maintain
alignment with Purpose-First Principles as Achievement is sought and accomplished.
      `,
      image: service4,
      name: 'News'
    },    
  ]
};


// Consortium Page
export const consortiumpage ={
  headTitle: `${enterprise.name} | Companies`,
  heroHeading: 'Consortium',
  heroImage: arGold,
  heading: '## Aurarian Consortium',
  contentImage: consortiumC,
  information: `
A's Consortium consists of member organizations who have aligned their mission and vision with the power and efficacy of Purpose First.
From the same firm foundation and with parallel trajectory, members of A's Consortium are Aurarians. 
  `,
  companiesHeading: '## Aurarians',
  companies: consortium
};

// Consortium Identity Page
export const consortiumIdentityPage = {
  headTitle: (entity) => `${entity.name} | Aurarians`,
  heroHeading: (entity) => entity.name,
  heroImage: servicesHeader,
  heading: (entity) => entity.name,
  contentImage: servicesHeader,
  information: (entity) => entity.longForm,
  consortium,
};

// Footer
export const footer = {
  image: {
    src: footerLogo,
    alt: 'A Company',
    title: 'A Company'
  },
  footerInformation: `A Company is the Premier Provider of Purpose-First Deliveries in Education, Entertainment, and Service Provider Value Adds.`,
  footerSocials: [{
    site: 'twitter',
    href: 'https://x.com/asgoodx'
  }, {
    site: 'window',
    href: enterprise.site_address
  }, {
    site: 'linkedin',
    href: 'https://www.linkedin.com/company/asgoodcompany/'
  }],
  footerColumns: [{
    title: `#### Useful Links`,
    navLinks: [
      {
        text: 'Home',
        href: '/'
      },
      {
        text: 'About Us',
        href: '/about'
      },
      {
        text: 'Services',
        href: '/services'
      }, {
        text: 'Careers',
        href: '/careers'
      },
      {
        text: 'Contact',
        href: '/contact'
      }
    ]
  }, {
    title: `#### Company`,
    navLinks: [
      {
        text: 'Consortium',
        href: '/consortium'
      },
      {
        text: 'Purpose First',
        href: 'https://purpose1st.org',
        newTab: true
      },
    ]
  }],
  footerServicesColumn: {
    on: true,
    title: '#### Services',
    services: servicespage.services,
  },
  footerContactHeading: `#### Contact`,
  footerContactGPSAddress: enterprise.address_gps,
  footerContactPhone: enterprise.phone,
  footerContactEmail: enterprise.email,
  footerCopyrightName: enterprise.name_legal,
};

// <head> meta content
export const sitehead = {
  siteTitle: 'A Company - Purpose-First VAS for Business and Individuals.',
  siteMeta: [
    {
      name: 'theme-color',
      content: '#d38b0e'
    },
    {
      name: 'description',
      content: enterprise.what_do
    }
  ]
};

// const o = {
//   enterprise,
//   homepage,
//   navbar,
//   aboutpage,
//   careerpage,
//   contactpage,
//   servicespage,
//   footer,
//   sitehead
// };

// function getDataTypes(obj) {
//   let result = {};

//   // Iterate over the keys of the object
//   for (let [key, value] of Object.entries(obj)) {
//     const valueType = Array.isArray(value) ? 'array' : (value && typeof value === 'object' ? 'object' : typeof value);

//     // If the value is an object or array, recursively call getDataTypes
//     result[key] = valueType === 'object' || valueType === 'array' ? getDataTypes(value) : valueType;

//     if (result[key] === 'string' && (/\n/.test(value) || value.length > 50)) result[key] = 'long_string';
//   }

//   return result;
// }

// console.log(getDataTypes(o))