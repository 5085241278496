import { BrowserRouter, Routes, Route, } from "react-router";
import { lazy, Suspense, useEffect } from "react";
import AOS from "aos";
import { HeadProvider } from 'react-head';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import '../node_modules/aos/dist/aos.css'
import Preloader from "./components/Preloader";
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import SiteHead from './components/SiteHead';
const Home = lazy(() => import("./pages/Home"));
const Career = lazy(() => import("./pages/Career"));
const Contact = lazy(() => import("./pages/Contact"));
const About = lazy(() => import("./pages/About"));
const Service = lazy(() => import("./pages/Service"));
const NoPage = lazy(() => import("./pages/NoPage"));
const Consortium = lazy(() => import('./pages/Consortium'));
const ConsortiumIdentity = lazy(() => import('./pages/ConsortiumIdentity'));

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <BrowserRouter>
        <HeadProvider>
          <SiteHead />
          <Suspense fallback={<Preloader/>}>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="services" element={<Service />} />
              <Route path="careers" element={<Career />} />
              <Route path="contact" element={<Contact />} />
              <Route path="consortium" element={<Consortium />} />
              <Route path="consortium/:identity" element={<ConsortiumIdentity />} />
              <Route path="*" element={<NoPage />} />       
            </Routes>
            <Footer/>
          </Suspense>
        </HeadProvider>
      </BrowserRouter> 
    </>
  );
}

export default App;
