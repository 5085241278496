import React from "react";
import { Link } from "react-router";
import { HashLink } from "react-router-hash-link";
import { marked } from 'marked';

import { footer } from '../site_content';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-content">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-12 footer-info">
                <Link to="/" className="logo d-flex align-items-center">
                  <img src={footer.image.src} alt={footer.image.alt} title={footer.image.title} />
                </Link>

                <div dangerouslySetInnerHTML={{ __html: marked(footer.footerInformation) }}></div>
                <div className="social-links d-flex mt-3">
                  {footer.footerSocials.map((button, index) => (
                    <Link to={button.href} className={button.site} key={`${button.site}-${index}`} className="icon">
                      <i className={`bi bi-${button.site}`}></i>
                    </Link>
                  ))}
                </div>
              </div>

              {footer.footerColumns.map((column, index) => (
                <div className="col-lg-2 col-6 footer-links" key={column.title}>
                  <div dangerouslySetInnerHTML={{ __html: marked(column.title) }}></div>
                  <ul>
                    {column.navLinks.map((link, index) => (
                      <li key={`${link.text}-${link.index}`}>
                        <i className="bi bi-dash"></i>
                        <Link to={link.href} target={link.newTab ? '_blank' : ''}>{link.text}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              {footer.footerServicesColumn && footer.footerServicesColumn.on && (
                <div className="col-lg-3 col-6 footer-links">
                  <div dangerouslySetInnerHTML={{ __html: marked(footer.footerServicesColumn.title) }}></div>
                  <ul>
                    {footer.footerServicesColumn.services.map((service, index) => (
                      <li key={`${service.id}`}>
                        <i className="bi bi-dash"></i>
                        <HashLink smooth to ={`/services/#${service.id}`}>
                          {service.name}
                        </HashLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <div dangerouslySetInnerHTML={{ __html: marked(footer.footerContactHeading) }}></div>
                <address>
                  <div dangerouslySetInnerHTML={{ __html: marked(footer.footerContactGPSAddress) }}></div>
                  <strong>Phone:</strong> <span>{footer.footerContactPhone}</span><br />
                  <strong>Email: </strong> <Link to={`mailto:${footer.footerContactEmail}`}>{footer.footerContactEmail}</Link>
                </address>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-legal">
          <div className="container">
            <div className="copyright">
              <p>
                {footer.footerCopyrightName}
              </p> 
              Copyright &copy; {year} All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
      
    </>
  )
  
};

export default Footer;
