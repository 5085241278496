import ReactDOM from 'react-dom/client';

import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Application performance metrics
const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ onCLS, onFID, onFCP, onLCP, onINP, onTTFB }) => {
      onCLS(onPerfEntry); // LayoutShift
      onFID(onPerfEntry); // PerformanceEventTiming
      onFCP(onPerfEntry); // PerformancePaintTiming
      onLCP(onPerfEntry); // LargestContentfulPaint
      onINP(onPerfEntry); // PerformanceEventTiming
      onTTFB(onPerfEntry); // PerformanceNavigationTiming
    });
  }
};
reportWebVitals();
