import { Title, Meta } from 'react-head';
import { sitehead } from '../site_content';

const SiteHead = () => (
	<>
		<Title>{sitehead.siteTitle}</Title>
		{sitehead.siteMeta.map((meta, key) => (
			<Meta content={meta.content} name={meta.name} key={`meta-${key}`} />
		))}
	</>
);

export default SiteHead;